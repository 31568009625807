<template>
  <v-container class="d-flex flex-column">
    <v-card
      class="elevation-0 pa-3 mt-4 flex-column flex-md-row justify-center align-center align-md-start"
    >
      <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />
      <v-form
        v-on:submit.prevent
        ref="form"
        v-model="isValid"
        :lazy-validation="lazy"
      >
        <v-text-field
          v-model="email"
          label="Email *"
          required
          outlined
          dense
          clearable
          :rules="emailRules"
          @keyup.enter="handleResetPasswordRequest"
        ></v-text-field>
        <v-card-actions class="pa-0">
          <!-- to="/register" -->
          <v-btn
            @click="handleResetPasswordRequest"
            class="reset-button"
            color="primary"
            large
            :block="$vuetify.breakpoint.xs"
            min-width="200"
            height="40"
            depressed
            :loading="loading"
            :disabled="disableFormSubmission"
            >{{ $t("login.submitResetPassword") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import aliRegistrationService from "@/service/aliRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";
import {
  requiredValue,
  isEmail
  // isLength
} from "~/validator/validationRules";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      isValid: false,
      loading: false,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      response: {}
    };
  },
  computed: {
    disableFormSubmission() {
      return !this.isValid || this.email.length <= 0;
    }
  },
  methods: {
    async handleResetPasswordRequest(e) {
      let _this = this;
      e.preventDefault();
      let mail = _this.email.match(/\S+@\S+\.\S/) ? _this.email : undefined;
      let fidelity = _this.email.match(/^.[0-9]*$/) ? _this.email : undefined;
      _this.response = {};
      if (_this.isValid) {
        _this.loading = true;
        let res = null;
        try {
          res = await aliRegistrationService.getResetPasswordToken(
            mail,
            undefined,
            fidelity
          );
          if (res.response?.status == 2) {
            _this.$router.push({
              name: "ResetPasswordConfirm",
              params: { message: res.response, mail: mail, login: fidelity }
            });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          console.log(err);
        } finally {
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  }
};
</script>
